.ReactPasswordStrength {
    /* border: 1px solid #c6c6c6; */
    box-sizing: border-box;
    /* color: #090809; */
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
    /* font-size: 18px; */
    /* line-height: 1; */
    position: relative;
}

/* .ReactPasswordStrength.form-control {
    padding: 8px 0;
} */

.ReactPasswordStrength-input {
    border: none;
    box-sizing: border-box;
    font-size: 18px;
    padding: 0;
    width: calc(85% - 28px);
}

.ReactPasswordStrength-input:not(:focus).is-password-invalid {
    color: #d1462f;
}

.ReactPasswordStrength-input:focus {
    outline: none;
}

.ReactPasswordStrength-strength-desc {
    color: transparent;
    font-style: italic;
    padding: 14px 12px;
    position: absolute;
    top: -3px;
    right: 0;
    text-align: right;
    transition: color 250ms ease-in-out;
    width: 15%;
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-desc {
    color: #d1462f;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
    color: #d1462f;
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-desc {
    color: #57b8ff;
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-desc {
    color: #57b8ff;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-desc {
    color: #2fbf71;
}

.ReactPasswordStrength-strength-bar {
    box-sizing: border-box;
    height: 3px;
    position: relative;
    top: -3px;
    left: 1px;
    transition: width 300ms ease-out;
    width: 0;
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-bar {
    background: #d1462f;
    width: 20%;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar {
    background: #d1462f;
    width: 40%;
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar {
    background: #57b8ff;
    width: 60%;
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar {
    background: #57b8ff;
    width: 80%;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
    background: #2fbf71;
    width: calc(100% - 2px);
}
