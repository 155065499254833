.DayPickerInput-Overlay {
    z-index: 999;
}

.DayPicker-Day.DayPicker-Day--range {
    color: #628473;
    background-color: #D0DDD6;
}

.DayPickerInput > .form-control[readonly] {
    background-color: #ffffff;
    border-radius: 20px;
}

.DateRangePicker-Clear-Btn {
    display: inline-block;
    width: 10%;
    padding-left: 2px;
    cursor: pointer;
}

.DateRangePicker-Clear-Btn:hover {
    color: red;
}

.DayPickerInput.width100 {
    width: 100%;
}
