/*.Sidebar__sidebar-toggle {
  padding: 0;
  border: 0px solid transparent;
  border-radius: 4px;
  background-image: none;
  background-color: transparent;
  display: block;
  width: 36px;
  height: 36px;
  font: 36px/36px "Font Awesome 5 Pro";
  color: #FFF;
  text-align: center
}*/

/*.Sidebar__sidebar-toggle:hover {
  background-color: #383f45;
}*/

/*.Sidebar__sidebar-toggle .icon-bar {
  display: none;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #888;
}*/

.Sidebar__sidebar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

/*.Sidebar__wrapper {
  width: 220px;
  top: 50px;
  background-color: #eaeaea;
  height: 100%;
  padding: 1px;
  ho spostato tutto in style.less per pusare le variabili
}*/

.Sidebar__wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/*.Sidebar__wrapper li a {
    color: #b9bbbe;
    font-size: 16px;
    padding: 7px 22px;
    text-decoration: none;
    line-height: 24px;
    display: block;
}

.Sidebar__wrapper li a:hover {
    background-color: #383f45;
}

.Sidebar__wrapper li a.active {
    color: #fff;
    background: #383f45;
}*/

.Sidebar__navbar-toggle,
.Sidebar__sidebar-toggle {
  float: left;
  position: relative;
  padding: 0;
  margin: 4px 5px 4px 0;
  border: 0;
  display: block;
  width: 36px;
  height: 36px;
  /* font: 36px/36px "Font Awesome 5 Pro"; */
    color: #fff;
  text-align: center;
  background: none;
}

.Sidebar__navbar-toggle .fas.fa-bars {
  font-size: 18px;
}

.Sidebar__sidebar-toggle {
  float: none !important;
  padding: 8px 0 8px 15px;
  margin-left: 10px;
}

/* .Sidebar__navbar-toggle:before,
.Sidebar__sidebar-toggle:before {
  content: '\f0c9';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
} */

/* .sidebar-is-open .Sidebar__navbar-toggle:before {
  content: '\f00d';
} */

.Sidebar__navbar-toggle:hover {
  background-color: transparent;
}

.Sidebar__navbar-toggle .icon-bar {
  display: none;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
}

.Sidebar__navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

.Sidebar__collapse_toggle,
.Sidebar__collapse_sub_toggle {
  background: #628473;
  width: 100%;
  padding: .5rem;
  text-transform: capitalize;
  color: #fff;
  cursor: pointer;
  position: relative;
  font-size: 1.125rem;
}

.Sidebar__collapse_sub_toggle {
  background: #94cb78;
  padding: .5rem .8rem;
}

.Sidebar__collapse_toggle::after,
.Sidebar__collapse_sub_toggle::after {
  font-family: "Font Awesome 5 Pro", serif;
  position: absolute;
  content: '\f105';
  top: .5rem;
  right: .5rem;
  color: #fff;
  transition: all 0.5s ease;
}

.Sidebar__collapse_toggle.rotate::after,
.Sidebar__collapse_sub_toggle.rotate::after {
  content: '\f107';
}

.sub-collapse-bg {
  background: #d6e5cc;
    padding: 0.5rem 0.8rem;
}

.g--text-green {
    color: #59B28F;
}